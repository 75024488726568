.textHead {
  text-align: center;
  color: black;
  font-size: 20px !important;
  font-weight: 700 !important;
}

.dialog-btnss {
  margin-top: 0px !important;
}
