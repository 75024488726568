.bg-sidebar {
  background-color: var(--Primary-Blue-300);
}
.active {
  background-color: #f7f7f7;
  color: blue;
}
.active2 {
  width: 305.25px;
  height: 60.16px;
  display: flex;
  align-items: center;
  border-radius: 61px;
}

.side-list {
  display: flex;
  padding: 0px;
  flex-direction: column;
}

.icon-cont,
.pro {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  width: 65px;
  cursor: pointer;
  padding: 3px 0;
}

.icon-cont:hover {
  background-color: #f7f7f7;
}
.sidebar {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: fixed;
  left: 0;
  top: 0;
  width: 65px;
  box-shadow: 0 0 10px #ddd;
  min-height: 100vh;
}
.sidebar > h3 {
  margin-bottom: 12px;
  color: white;
}
.sidebar > h3::before {
  content: "";
  background-color: white;
  width: 80px;
  height: 2px;
  position: absolute;
  bottom: -20px;
  transform: translateX(-50%);
  left: 50%;
}

.side-li {
  cursor: pointer !important;
  list-style: none !important;
}

.sidebar ul li div span {
  display: none;
}
.sidebar > h3 {
  font-size: 20px;
  margin-bottom: 15px;
}
.sidebar > h3::before {
  display: none;
}

@media (max-width: 767px) {
  .sidebar {
    width: 62px;
    padding: 10px;
  }
  .sidebar ul li div span {
    display: none;
  }
  .sidebar > h3 {
    font-size: 20px;
    margin-bottom: 15px;
  }
  .sidebar > h3::before {
    display: none;
  }
  .active {
    width: 70px;
  }
}
