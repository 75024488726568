.errorCont button {
  background-color: #ff000029 !important;
  color: #ff0000 !important;
  box-shadow: none !important;
  text-transform: capitalize !important;
}
.savedLoading {
  display: flex;
  align-items: center;
}

.savedText {
  color: #858585;
}

.back{
  margin: 0px !important;
}