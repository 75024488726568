.searchContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  position: relative;
}

.searchContainer .TextHeaderDiv {
  color: #333333;
  width: 150px;
  border-bottom: 2px solid #333333;
  text-align: center;
  font-weight: bold;
  padding: 10px 0px;
  cursor: pointer;
  transition: all 0.3s;
}

.searchContainer .TextHeaderDiv:hover {
  background-color: #d9d9d9;
}

.group {
  display: flex;
  line-height: 28px;
  align-items: center;
  position: relative;
}

.noDataImgCont {
  position: absolute;
  right: 25px;
  top: -117px;
}
