:root {
  --back-ground-color: #f6fafd;
}
.deleteBtn {
  font-weight: 800 !important;
  color: var(--back-ground-color) !important;
  margin: 20px 0 35px 0 !important;
  min-width: 110px !important;
  min-height: 40px !important;
  transition: all 0.3s !important;
}

.deleteBtn:hover {
  background-color: #eb1515 !important;
}

.deleteBtn:active {
  background-color: #eb1515 !important;
}

.deleteBtn[disabled] {
  /* Add your custom styles for disabled buttons here */
  background-color: #eb15158c !important; /* Change the background color */
  color: var(--back-ground-color) !important; /* Change the text color */
  cursor: not-allowed !important; /* Change the cursor style */
}
