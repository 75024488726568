:root {
  --main-color: #333333; /* all the texts colors */
  --second-color: #d9d9d9; /* buttons background color */
  --simple-back-ground-color: #ffffff; /* original background for any thing*/
  --back-ground-color: #f6fafd; /* hover || style any background */
  --border-color: #7d7d7d; /* any border color */
  --root-color: #12bbc2; /* not used yet*/
  --header-color: #6e48aa;
}
.viewer-container {
  background-color: #000000b3;
  height: 100vh;
}

.viewer-container .chatBot {
  position: fixed;
  right: 100px;
  float: right;
  bottom: 105px;
  z-index: 5000;
}

.viewer-container .chatBotIconContainer {
  height: 60px;
  width: 60px;
  border-radius: 50%;
  position: fixed;
  bottom: 20px;
  right: 20px;
  line-height: 50%;
}

.viewer-container .chatBotIconContainer .chatBotIcon {
  color: #6345c7;
  position: absolute;
  top: -12px;
  left: -30px;
  cursor: pointer;
}

.viewer-container .chatBotIconContainer .chatBotIcon .niyat-icon {
  width: 80px;
  height: 60px;
  cursor: pointer;
}

.viewer-container .chatBotSaveDate {
  height: 60px;
  width: 60px;
  border-radius: 50%;
  position: fixed;
  bottom: 20px;
  left: 20px;
  line-height: 50%;
}

@keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-15px);
  }
  60% {
    transform: translateY(-10px);
  }
}

.bounce {
  animation: bounce 1.5s infinite;
}

.rsc-header {
  background-color: #a7abaa !important ;
}

.rsc-header-title {
  font-size: 18px !important;
}

.rsc-content {
  background-color: white !important;
  font-family: "Nunito", sans-serif !important;
  height: calc(520px - 22px) !important;
}

.rsc-content .rsc-ts-bubble {
  background-color: #f7f7f7 !important;
  color: var(--main-color);
  margin-bottom: 13px !important;
}

