.stepHeader {
  padding: 10px 10px 0px 10px;
  width: 100%;
}

.stepHeader .logoCont {
  margin-left: 10px;
}

.stepHeader .logoCont img {
  -webkit-animation: Lmuha 0.3s ease forwards;
  animation: Lmuha 0.3s ease forwards;
  border-radius: 50% 50% 0 50%;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 1px 2px 0px;
  height: 40px;
  min-width: 40px;
  padding: 3px;
  -webkit-transform-origin: bottom right;
  -ms-transform-origin: bottom right;
  transform-origin: bottom right;
  width: 40px;
}

.header {
  margin: 0 !important;
  font-size: 16px !important;
  text-transform: capitalize;
  text-align: start;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
  margin-top: 10px !important;
  color: var(--main-color);
  flex: 2;
  width: 100px !important; 
  word-wrap: break-word !important;
  padding-right: 10px !important;
}
