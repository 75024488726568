.loaderCont {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.AllChatsContainer {
  max-width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
}
.chatCont {
  margin: auto;
  margin-top: 25px;
  padding: 15px;
}
.chatContent {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 25px;
  flex-direction: column;
  margin-top: 100px;
}
