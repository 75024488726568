.loginCont {
  width: 100%;
  height: 100vh;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
}
.loginCont .formCont {
  width: 419px;
  min-width: 320px;
  max-width: 419px;
  max-height: 607.53px;
  background-color: #fff;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  border-radius: 5px;
  box-sizing: border-box;
  padding: 41px 61px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.loginCont .formCont img {
  width: 80.169px !important;
  height: 80.761px !important;
}
.loginCont .formCont .words {
  margin: 10px 0 30px 0;
}

.loginCont .formCont .words .title {
  text-align: center;
  font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
    "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
  font-size: 24px;
  font-weight: 400;
  margin: 0px !important;
  margin-bottom: 5px !important;
  line-height: 28.8px;
}

.loginCont .formCont .words .someTools {
  font-size: 14.18px !important;
  line-height: 18px;
  font-weight: 400;
}

.loginCont .formCont form {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 18px;
  margin-bottom: 15px;
}

.loginCont .formCont form .errorValidation {
  color: red !important;
  font-size: 13px !important;
  margin-top: 2px !important;
}

.loginCont .formCont form .signIn {
  margin-top: 10px !important;
}

.signIn {
  background-color: var(--second-color) !important;
  color: var(--main-color) !important;
  font-weight: 800 !important;
  min-width: 110px !important;
  min-height: 40px !important;
  transition: all 0.3s !important;
  box-shadow: 1px 1px 3px #ccc !important;
}

.signIn:hover {
  background-color: #d7c2c2 !important;
  box-shadow: 1px 1px 13px #ccc !important;
}

.signIn:active {
  background-color: #d7c2c2 !important;
  box-shadow: 1px 1px 13px #ccc !important;
}

.signIn[disabled] {
  /* Add your custom styles for disabled buttons here */
  background-color: #ccc !important; /* Change the background color */
  color: #666 !important; /* Change the text color */
  cursor: no-drop !important; /* Change the cursor style */
}

.forgetPassCont {
  display: flex;
  justify-content: flex-end;
  margin-top: -16px;
  margin-right: 5px;
}

.MandatoryStars{
  color: red;
}
