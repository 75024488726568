.dialogTitle {
  text-align: center;
  font-weight: 800 !important;
}
.textHead {
  font-size: 14px;
  color: #858585;
}

.shareCont {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 25px;
}

.shareCont .shareLink {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  border: 1px solid black;
  padding: 10px;
  border-radius: 4px;
}
.shareCont .shareLink p{
  width: 330px;
  word-break: break-all;
}
.copyIcon {
  cursor: pointer;
}
