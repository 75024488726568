.mb10 {
  margin-bottom: 10px !important;
}
.mb20 {
  margin-bottom: 20px !important;
}
.mb40 {
  margin-bottom: 40px !important;
}
.howToUseListDecimal li {
  list-style: decimal !important;
  cursor: default !important;
}
.howToUseList li {
  list-style: disc !important;
  cursor: default !important;
}
.asideLinks {
  display: block;
  line-height: 175%;
}

ul .hint {
  background-color: #fffbed;
  width: fit-content;
  padding: 5px;
}

a:hover {
  color: #2a7cff;
  cursor: pointer;
}
main {
  text-align: start !important;
}

.prevNextCont {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: 20px;
  margin-top: 60px;
  margin-bottom: 30px;
  position: relative;
}

.prevNextCont::before {
  content: "";
  position: absolute;
  width: 60%;
  height: 2px;
  background-color: #ccc;
  top: 135%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.prevCont,
.nextCont {
  transition: transform 0.3s ease;
  position: relative;
  top: 0;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  width: 35%;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.prevCont:hover,
.nextCont:hover {
  box-shadow: 0px 12px 13px rgba(0, 0, 0, 0.02);
  transform: translateY(-2px);
}

.prevCont:hover .prevIcon,
.prevCont:hover .prevText {
  color: #2a7cff;
  transition: color 0.1s ease 0.1s;
}

.nextCont:hover .nextIcon,
.nextCont:hover .nextText {
  color: #2a7cff;
  transition: color 0.1s ease 0.1s;
}
.prevText {
  align-self: flex-end;
}

.nextText {
  align-self: flex-start;
}

.nextText,
.prevText {
  font-size: 16px !important;
  font-weight: 700 !important;
}

.prevTextCont,
.nextTextCont {
  display: flex;
  flex-direction: column;
}

.nextTextBlock,
.prevTextBlock {
  font-size: 12px !important;
  font-weight: 400 !important;
  color: #656973 !important;
}

.pointerNavCont {
  max-width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow-x: hidden;
  overflow-y: hidden;
}

.pointerNavCont a {
  color: rgba(92, 105, 117, 1);
}

.pointerNavCont a:hover {
  color: #2a7cff;
}
.pointerNav {
  font-size: 14px !important;
  font-weight: 400 !important;
  line-height: 22px !important;
}

.linkActive {
  border-left: 3px solid #2a7cff;
}

.linkActive a {
  color: #2a7cff !important;
}

.importantText {
  background-color: #f5f7f9;
  border-radius: 4px;
  border-left: 6px solid #e3e8ed;
  padding: 20px;
  margin-top: 30px;
  display: flex;
}

.allLinksCont {
  position: fixed;
  right: 0;
  width: 12%;
}

.LinksTitle {
  font-weight: 700;
  color: #656973;
  font-size: 12px;
  letter-spacing: 1.2px;
  line-height: 18px;
}

.importantIcon {
  margin-right: 10px;
}

@media only screen and (max-width: 600px) {
  .asideLinks {
    display: none !important;
  }
  main {
    text-align: center !important;
    padding: 15px !important;
  }
  .textHead {
    font-size: x-large;
  }
  li {
    text-align: start !important;
  }
  .followInstructions {
    text-align: start !important;
  }
  .importantText {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10px !important;
  }
  .importantIcon {
    margin-right: 0px !important;
    margin-bottom: 10px;
  }
}

@media only screen and (max-width: 768px) {
  .prevNextCont {
    flex-wrap: wrap !important;
  }
  .prevCont,
  .nextCont {
    width: 100% !important;
  }
}

@media only screen and (min-width: 601px) and (max-width: 900px) {
  .asideLinks {
    display: none !important;
  }
}

@media only screen and (min-width: 601px) and (max-width: 1340px) {
  .asideLinks {
    display: none !important;
  }
}
