.container {
  max-width: 1215px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  height: auto;
  margin-bottom: 20px;
}

.industrySide {
  position: sticky;
  top: 0px;
  max-height: 100vh;
  padding-top: 134px;
  padding-left: 7px;
  width: 200px !important;
  overflow: hidden !important;
}

.industryCont {
  height: 100%;
  width: 100%;
  padding-left: 24px;
}

.industryText {
  font-size: 18px !important;
  font-family: CircularStd-Bold;
}

.templatesCont {
  padding-inline-start: 0px !important;
  padding-top: 0px;
  font-size: 14px;
  font-family: CircularStd-Book;
  line-height: 1.43;
  color: rgb(133, 133, 133);
}

.templatesCont li {
  list-style: none !important;
  cursor: pointer !important;
}

.templatesCont li:hover {
  color: #2a7cff !important;
}

.templatesCont li:not(:last-child) {
  margin-bottom: 6px;
}

.linkActive {
  color: #2a7cff !important;
}

.content {
  width: 100%;
  overflow: hidden !important;
  padding: 10px 20px;
}

.contentHeader {
  display: flex;
  padding-top: 64px;
  position: absolute;
  max-width: 960px;
  padding-bottom: 12px;
  overflow-y: auto;
  background: rgb(255, 255, 255);
  z-index: 111;
  width: calc(100% - 275px);
  justify-content: space-between;
  gap: 20px;
}

.titleHeader {
  font-size: 24px !important;
  color: rgb(16, 16, 16);
  font-family: CircularStd-Bold;
}

.allTemplates {
  display: flex;
  position: relative;
  margin-top: 200px;
}

.templatesCont {
  width: 100%;
  margin-left: 14px;
}

.allCards {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 40px;
}

.card {
  background-color: white;
  min-height: 400px;
  box-shadow: 0px 0px 6px 0px #888888;
  border-radius: 10px;
}

.cardHeader img {
  max-width: 100%;
  min-height: 100%;
  border-radius: 10px 10px 0 0;
  width: 100%;
}

.cardContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 20px;
  min-height: 135px;
}

.cardContent h4 {
  font-family: CircularStd-Bold;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.43;
  color: rgb(16, 16, 16);
  margin: 0px 0px 16px 0px !important;
  padding: 0px !important;
}

.cardContent .ContentParagraph {
  font-family: CircularStd-Book, sans-serif !important;
  font-size: 14px !important;
  line-height: 1.43 !important;
  color: rgb(133, 133, 133) !important;
  margin: 0px !important;
  padding: 0px !important;
}

@media (max-width: 813px) {
  .cardContent{
    text-align: center;
  }
  .cardContent h4 {
    width: 100%;
    font-size: 25px;
    font-weight: bolder;
  }
  
  .cardContent .ContentParagraph {
    font-size: 18px !important;
  }
}

.cardFooter {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  gap: 10px;
  border-top: 1px solid #eee;
  padding: 20px 0px;
  margin: 0px 10px;
  flex-wrap: wrap;
}
.designBtn {
  background-color: var(--second-color) !important;
  color: var(--main-color) !important;
  font-weight: 600 !important;
  text-transform: capitalize !important;
  transition: all 0.3s;
}

.designBtn:hover {
  background-color: #bbafaf !important;
}

.designBtn[disabled] {
  background-color: #ccc !important; /* Change the background color */
  color: #666 !important; /* Change the text color */
  cursor: no-drop !important; /* Change the cursor style */
}

.previewBtn {
  color: var(--main-color) !important;
}

.previewBtn:hover {
  text-decoration: underline !important;
}
