.lowerChatCont {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.chatLength {
  color: #858585;
  font-size: 14px;
}
.card {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 650px;
  border: 2px solid #eee;
  border-radius: 10px;
  margin-bottom: 15px;
  transition: all;
  transition-duration: 0.5s;
  cursor: pointer;
  box-shadow: 0px 0px 4px 0px #ccc;
}

.card:hover {
  border: 2px solid blueviolet;
  box-shadow: 0px 0px 8px 0px #ccc;
}

.leftCard {
  width: 100%;
  cursor: pointer;
  padding: 20px;
  flex: 1;
}

.chatName {
  font-weight: normal;
  font-size: 18px;
}

.rightCard {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
}

.moreIcon {
  margin-right: 10px;
}

.moreIcon button {
  padding: 10px !important;
}

menuitem {
  cursor: pointer;
}

@media (max-width: 768px) {
  .card {
    width: 550px;
  }
}
.pagination {
  margin: 20px 0px;
  width: 600px;
  max-width: 100%;
  flex-wrap: wrap;
  display: flex;
  align-items: center;
  justify-content: space-around;
  gap: 5px;
}

.pagination .pageNumbers {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  flex-wrap: wrap;
  flex: 1;
}

.pagination .paginationBtn {
  background-color: #d9d9d9 !important;
  color: #333333;
  font-weight: 800;
}

/* delete options */
.deleteOptionsContainer {
  display: flex;
  gap: 5px;
}

.deleteChat {
  font-weight: 600 !important;
  color: white !important;
  min-width: 150px !important;
  transition: all 0.3s !important;
  background-color: #ff2a2a !important;
  text-transform: capitalize !important;
}

.deleteChat:hover {
  background-color: #eb1515 !important;
}

.deleteChat:active {
  background-color: #eb1515 !important;
}

.deleteChat[disabled] {
  /* Add your custom styles for disabled buttons here */
  background-color: #fc161698 !important; /* Change the background color */
  color: var(--back-ground-color) !important; /* Change the text color */
  cursor: not-allowed !important; /* Change the cursor style */
  pointer-events: inherit !important;
}
