:root {
  --main-color: #333333; /* all the texts colors */
  --second-color: #d9d9d9; /* buttons background color */
  --simple-back-ground-color: #ffffff; /* original background for any thing*/
  --back-ground-color: #f6fafd; /* hover || style any background */
  --border-color: #7d7d7d; /* any border color */
  --root-color: #12bbc2; /* not used yet*/
}
/* start styling repeated components */
.chatbot-container {
  width: 100%;
}

.chatbot-shadow {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border: 1px solid rgb(125 125 125 / 29%);
  border-radius: 4px;
  box-shadow: 0px 0px 10px 1px rgb(125 125 125 / 29%);
  background-color: var(--back-ground-color);
}

.step-header {
  padding: 10px 10px 0px 10px;
  width: 100%;
}

.step-header .logo-container {
  margin-left: 10px;
}

.step-header .logo-container img {
  -webkit-animation: Lmuha 0.3s ease forwards;
  animation: Lmuha 0.3s ease forwards;
  border-radius: 50% 50% 0 50%;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 1px 2px 0px;
  height: 40px;
  min-width: 40px;
  padding: 3px;
  -webkit-transform-origin: bottom right;
  -ms-transform-origin: bottom right;
  transform-origin: bottom right;
  width: 40px;
}

.header {
  margin: 0 !important;
  font-size: 16px !important;
  text-transform: capitalize;
  text-align: start;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
  margin-top: 10px !important;
  color: var(--main-color);
  flex: 2;
}
/* end styling repeated components */

/* start styling the date */
.calenedr {
  font-size: 16px;
  padding: 10px;
  max-width: 100%;
  margin: 0 !important;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}
.rdp-caption {
  background-color: var(--second-color);
  color: white;
  padding: 5px 10px;
  border-bottom: 1px solid rgb(125 125 125 / 29%);
  border-radius: 4px;
}
.rdp-button:hover:not([disabled]):not(.rdp-day_selected) {
  background-color: #ceb0b0;
}
/* end styling the date */

/*  start styling the time */
.timer {
  font-size: 16px;
  padding: 10px;
  max-width: 100%;
  margin: 20px 0px !important;
}
/* end styling the time */

/*  start styling the sliderOption */
.slider-option-submit-button {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
}
.slider-option-submit-button .css-1e6y48t-MuiButtonBase-root-MuiButton-root {
  background-color: var(--second-color) !important;
  border-radius: 10px !important;
  padding: 10px 20px !important;
  text-align: center !important;
  color: var(--main-color) !important;
  font-weight: bold !important;
}
.slider-option-submit-button
  .css-1e6y48t-MuiButtonBase-root-MuiButton-root:hover {
  background-color: #a7abaa !important;
  font-weight: 800 !important;
}
.option-form {
  width: 60%;
  margin-bottom: 20px;
  margin-top: 50px;
}
/* end styling the sliderOption */

/* start styling image slider */
.all-sliders {
  box-shadow: 0px 0px 10px 1px black;
  width: 250px;
  height: 100%;
}
.slick-prev:before {
  color: #a7abaa !important;
  font-size: 30px !important;
  position: absolute !important;
  left: -20px !important;
}
.slick-next:before {
  color: #a7abaa !important;
  font-size: 30px !important;
  position: absolute !important;
  right: -20px !important;
}
/* end styling image slider */

/* start styling forms */
.main-form {
  background-color: #f9f2f2;
  width: 100%;
  margin-top: 10px;
  padding: 10px 0px;
}
.divchatbot {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 20px 0px;
}
.divchatbotsubmit {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}
.inputchatbot {
  width: 90%;
  height: 60px;
}
.inputchatbot input {
  background-color: #f6fafd;
  color: var(--main-color) !important;
}
.inputchatbot:focus {
  border: 1px solid rgb(125 125 125 / 100%);
}
.divchatbotsubmit .css-1e6y48t-MuiButtonBase-root-MuiButton-root {
  background-color: var(--second-color) !important;
  border-radius: 10px !important;
  padding: 10px 20px !important;
  text-align: center !important;
  color: var(--main-color) !important;
  font-weight: bold !important;
}
.divchatbotsubmit .css-1e6y48t-MuiButtonBase-root-MuiButton-root:hover {
  background-color: #a7abaa !important;
  font-weight: 800 !important;
}

.error-message {
  color: red !important;
  font-size: 13px !important;
  align-self: flex-start;
  padding-left: 25px;
  width: 87%;
}
/* end styling forms */

/* start styling file upload chat */
.error-message-container {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 95%;
  margin-bottom: 10px;
}
.error-message-container .error-message {
  color: red;
  font-size: 13px;
}

.start-upload-files-cont {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  width: 100%;
}

.start-upload-files-cont
  .upload-without-files
  .css-1d6wzja-MuiButton-startIcon {
  margin: 0px !important;
}

.start-upload-files-cont .upload-without-files {
  color: #b1b8bb !important ;
  display: flex !important ;
  justify-content: center !important ;
  align-items: center !important ;
  font-weight: 800 !important ;
  flex-direction: column !important ;
  width: 80% !important ;
  outline: 1px dashed !important ;
  border-radius: 4px !important ;
  padding: 40px 10px !important ;
  background-color: white !important ;
}

.start-upload-files-cont .msg-start-upload {
  color: #b1b8bb;
  text-align: center;
  text-transform: capitalize;
  font-size: 14px;
  margin-top: 10px;
}

@keyframes upDown {
  0% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-15px);
  }
  60% {
    transform: translateY(-10px);
  }
}

.second-error-msg-cont {
  margin-top: 20px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 95%;
  margin-left: 20px;
}

.files-container-bf-sub {
  max-height: 190px;
  overflow-y: auto;
  width: 95%;
  max-width: 95%;
  overflow-x: hidden;
  margin-top: 20px;
}

.files-container-bf-sub .file-with-delete-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 94%;
  padding: 10px;
  margin: 10px 0px;
  border-radius: 4px;
}

.files-container-bf-sub
  .file-with-delete-container
  .file-texts-cont
  .file-name {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 210px;
}

.files-container-bf-sub
  .file-with-delete-container
  .file-texts-cont
  .file-size-max-cont {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  color: #afa0a0;
}

.files-container-bf-sub
  .file-with-delete-container
  .file-texts-cont
  .file-size-max-cont
  .file-size {
  font-size: 13px;
  margin-right: 20px;
}

.files-container-bf-sub
  .file-with-delete-container
  .file-texts-cont
  .file-size-max-cont
  .max-file-size {
  font-size: 13px;
}

.files-container-bf-sub .file-with-delete-container .delete-file-icon {
  cursor: pointer;
  color: #afa0a0;
}

.buttons-cont {
  margin-top: 100px;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
}

.buttons-cont .add-file-cont {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px 0px;
}

.buttons-cont .add-file-cont .add-files-btn {
  background-color: #a7abaa !important;
  color: white !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  font-size: 13px !important;
}

.buttons-cont .suib-file-cont {
  display: flex;
  justify-content: center;
  align-items: center;
}

.buttons-cont .suib-file-cont .submit-btn {
  background-color: #a7abaa !important;
  color: white !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  font-size: 13px !important;
}

.no-drop {
  cursor: no-drop !important;
  background-color: #d9d9d9 !important;
}

.submit-btn.no-drop,
.add-files-btn.no-drop {
  cursor: no-drop !important;
  background-color: #d9d9d9 !important;
  pointer-events: visibleFill !important;
}

/* Add this to your stylesheet */
@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  25% {
    opacity: 0.75;
  }
  50% {
    opacity: 0.5;
  }
  75% {
    opacity: 0.25;
  }
  100% {
    opacity: 0;
  }
}

.fade-out {
  animation: fadeOut 0.5s ease-in-out;
}
/* end styling file upload chat */

/* start styling send file message */
.message-file-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 262px;
  height: fit-content;
  cursor: pointer;
  max-width: 100%;
  flex-direction: column;
}

.message-file-container .file-extension {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  border: 6px solid #ddd;
  padding: 5px;
}

.message-file-container .text-file-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  margin-top: 10px;
}

.message-file-container .text-file-container .text-file {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 60%;
}
/* end styling send file message */

/* start styling send quill message */
.quill-container {
  line-height: 1.8;
  width: 260px !important;
  word-wrap: break-word !important;
}
.quill-container ol li {
  list-style: decimal !important;
}
.quill-container ul li {
  list-style: disc !important;
}
.rsc-ts-bubble {
  max-width: 75% !important;
}
/* end styling send quill message */

/* start styling Carousel */
.Carousel-view-btns {
  background-color: var(--second-color) !important;
  color: var(--main-color) !important;
  font-weight: 800 !important;
  width: 100% !important;
  max-width: 100% !important;
  min-height: 40px !important;
  transition: all 0.3s !important;
  box-shadow: 1px 1px 3px #ccc !important;
  text-transform: none !important;
  margin: 0px !important;
  word-break: break-word !important;
}

.Carousel-view-btns:hover {
  background-color: #d7c2c2 !important;
  box-shadow: 1px 1px 13px #ccc !important;
}

.Carousel-view-btns:active {
  background-color: #d7c2c2 !important;
  box-shadow: 1px 1px 13px #ccc !important;
}

.Carousel-view-btns[disabled] {
  /* Add your custom styles for disabled buttons here */
  background-color: #ccc !important; /* Change the background color */
  color: #666 !important; /* Change the text color */
  cursor: no-drop !important; /* Change the cursor style */
}

/* end styling Carousel */

.rsc-cs {
  box-shadow: rgba(0, 0, 0, 0.15) 0px 1px 9px 0px !important;
}

.gIMgwn:has(.email-child) {
  display: none !important;
}

@media (max-height: 700px) {
  .sc-jrsKJM {
    left: 25px !important;
    top: 10px !important;
    overflow-y: scroll !important;
  }
}

.dateTypeContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #0000003b;
  border-radius: 4px;
  position: relative;
  width: 90%;
  height: 60px;
  background-color: #f6fafd;
  color: var(--main-color) !important;
}

.react-datepicker-wrapper {
  flex: 2;
}

.react-datepicker__input-container input {
  width: 82.1%;
  border: none;
  outline: none;
  cursor: pointer;
  border-radius: 4px;
}
.dateTypeContainer .react-datepicker__input-container input {
  padding: 19.5px 40px 19.5px 10px;
}

.my-calender {
  position: absolute;
  z-index: 1000;
}
.dateTypeContainer .my-calender {
  right: 8px;
  left: auto;
}

.react-datepicker-wrapper .react-datepicker__input-container input {
  font-size: 17px !important;
  color: var(--main-color) !important;
  background-color: #f6fafd !important;
}
.react-datepicker-wrapper
  .react-datepicker__input-container
  input::placeholder {
  color: #aeb1b3 !important;
}

.react-datepicker-wrapper .react-datepicker__input-container input:disabled {
  color: #aeb1b3 !important;
  background-color: #e8f0fe !important;
  cursor: default !important;
}

.disabledDatePicker .my-calender {
  /* Set the color of the icon when disabled */
  color: #aeb1b3 !important;
  cursor: default !important;
}
