:root {
  --main-color: #333333; /* all the texts colors */
  --second-color: #d9d9d9; /* buttons background color */
  --simple-back-ground-color: #ffffff; /* original background for any thing*/
  --back-ground-color: #f6fafd; /* hover || style any background */
  --border-color: #7d7d7d; /* any border color */
  --root-color: #12bbc2; /* not used yet*/
}

/* start not yer */
/* .message {
  border: 0;
  border-bottom: 2px solid lightgray;
  font-size: 1.2rem;
  padding: 5px 0;
  width: 100%;
}

.message:focus {
  border: 0;
  border-bottom: 2px solid blueviolet;
  outline: none;
}

.close {
  background-color: transparent;
  width: 50px;
  border: none;
  color: red;
  font-size: 20px;
  cursor: pointer;
}

.add-btn-text {
  color: var(--main-color);
  font-size: 1.1rem;
}

.days {
  width: 100%;
  background: #fff;
  box-shadow: 0 30px 30px rgba(0, 0, 0, 0.05);
  margin-top: 10px;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
}

.days label {
  width: 100%;
  padding: 5px;
  margin: 5px;
  color: gray;
}

.days label:hover {
  background-color: rgba(128, 128, 128, 0.1);
  color: b;
}

.upove-input {
  margin-bottom: 5px;
}

.under-type {
  font-size: 0.9rem;
  color: gray;
}

.under-input {
  font-size: 0.8rem;
  color: gray;
  margin-top: 4px;
}

.text-box {
  cursor: pointer;
  background: var(--simple-back-ground-color);
  border: 1px solid var(--border-color);
  width: 100%;
  height: 30px;
  padding: 12px 20px;
  border-radius: 5px;
  height: 40px;
  font-size: 1rem;
}

.text-box::placeholder {
  color: var(--main-color);
}

.text-box:focus {
  outline: none;
  border-color: var(--main-color);
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1);
}
.options {
  width: 100%;
  background: #fff;
  box-shadow: 0 30px 30px rgba(0, 0, 0, 0.05);
  margin-top: 10px;
  border-radius: 5px;
}

.option {
  padding: 12px 20px;
  cursor: pointer;
}

.option i {
  margin-right: 5px;
}

.option:hover {
  background: var(--main-color);
  color: #fff;
}
*/
/* end not yer */

li {
  list-style: none !important;
}

/* start styling the whole slider */
.slider {
  background-color: var(--simple-back-ground-color);
  border-left: 3px solid var(--border-color);
  position: fixed;
  top: 0;
  width: 350px;
  display: flex;
  flex-direction: column;
  border-bottom: 0;
  height: 100%;
  padding: 30px;
  z-index: 1000;
  transition: right 0.5s ease;
  overflow: scroll;
  margin-top: 64px;
}
/* end styling the whole slider */

/* start General component */
.slider-compBtn {
  margin-top: 15px;
  margin-bottom: 40px;
}

.slider-compBtn .message-container {
  margin-bottom: 20px;
}

.slider-compBtn .message-container .message-description {
  font-size: 0.95rem;
  color: var(--main-color);
  font-weight: 900;
}

.slider-comp {
  margin-bottom: 40px;
}

/* end General component */

/* start styling send Message */
.slider-comp .msg-desc {
  display: flex;
  justify-content: space-between;
}

.slider-comp .msg-desc .message-description {
  margin-left: 5px;
}

.slider-comp .msg-desc .delet-btn {
  background-color: transparent !important;
  border: none !important;
  cursor: pointer !important;
}

.slider-comp .msg-desc .delete-icon {
  color: gray;
}

.slider-comp .img-informations {
  padding: 20px 15px;
  margin: 0px;
  background-color: var(--back-ground-color);
  border-radius: 5px;
  box-shadow: -3px 4px 6px 0px;
}

.slider-comp .img-informations li {
  list-style: none !important;
}

.slider-comp .img-informations .spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px 0px;
}

.slider-comp .img-informations .image-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
}

.image-btn-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 35px;
}

.img-informations .image-container .img-text {
  text-align: center;
  overflow-wrap: break-word;
  width: calc(100% - 110px);
}

.img-informations .input-container {
  margin-top: 30px;
}

.adding-btns {
  margin: 15px 0;
  display: flex;
  width: 100%;
  justify-content: space-evenly;
  align-items: flex-start;
  border-top: 2px solid var(--border-color);
}

.image-btn-container .upload-img {
  margin: 20px 0 35px 10px !important;
  background-color: var(--second-color) !important;
  color: var(--main-color) !important;
  font-weight: 800 !important;
  min-width: 110px !important;
  min-height: 40px !important;
}
/* end styling send Message */

/* start Form */
.forms-cont {
  margin-bottom: 130px;
}

.forms-cont .message-container {
  margin-bottom: 20px;
}

.forms-cont .message-container .message-description {
  font-size: 0.95rem;
  color: var(--main-color);
  font-weight: 900;
}
.fileds-container {
  display: flex;
  flex-direction: column;
}

.fileds-container .the-whole-field {
  border: 1px solid var(--border-color);
  background-color: var(--back-ground-color);
  border-radius: 5px;
}

.fileds-container .input-types {
  margin-bottom: 20px;
}

.fileds-container .input-types .form-drag-handle {
  visibility: hidden;
  cursor: move;
}

.fileds-container .input-types:hover .form-drag-handle {
  visibility: visible;
}

.fileds-container .input-types .single-field {
  padding: 10px 0px 10px 10px;
  text-transform: capitalize;
  display: flex;
  align-items: center;
  justify-content: center;
}

.fileds-container .input-types .single-field .field-icon {
  margin-right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.fileds-container .input-types .delete-field {
  cursor: pointer;
  border-left: 1px solid var(--border-color);
  padding: 10px;
}

.dialog-btns .form-field {
  margin-bottom: 10px !important;
}

/* start styling the new field dialog */
.dialog-title {
  text-align: center;
  font-weight: 800 !important;
}

.dialog-btns {
  margin-top: 25px;
}

.dialog-btns .add-btn,
.dialog-btns .close-btn {
  font-weight: 800 !important;
  color: var(--back-ground-color) !important;
}

.add-btn {
  background-color: var(--second-color) !important;
}
.back-top-form {
  margin: 0px !important;
}
/* end styling the new field dialog */
/* end Form */

/* start styling slider image */
.slider-compBtn .img-informations {
  padding: 20px 15px;
  margin: 0px;
  background-color: var(--back-ground-color);
  border-radius: 5px;
  box-shadow: -3px 4px 6px 0px;
}

.slider-compBtn .img-informations:not(:last-child) {
  margin-bottom: 30px;
}

.slider-compBtn .img-informations .spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px 0px;
}

.slider-compBtn .img-informations .image-container {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-wrap: wrap;
}

.spinner {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin: 30px 0px;
}

.standard-basic-url {
  margin-top: 20px !important;
}

.image-btn-cont {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 80px;
  border-top: 2px solid var(--border-color);
}

.image-btn-cont .upload-img {
  margin: 20px 0 35px 10px !important;
  background-color: var(--second-color) !important;
  color: var(--main-color) !important;
  font-weight: 800 !important;
  min-width: 110px !important;
  min-height: 40px !important;
  transition: all 0.3s !important;
  box-shadow: 1px 1px 3px #ccc !important;
  border: none !important;
  cursor: pointer !important;
}
.image-btn-cont .upload-img:hover {
  background-color: #d7c2c2 !important;
  box-shadow: 1px 1px 13px #ccc !important;
}

.image-btn-cont .upload-img:active {
  background-color: #d7c2c2 !important;
  box-shadow: 1px 1px 13px #ccc !important;
}

.image-btn-cont .upload-img.disabledBtn {
  /* Add your custom styles for disabled buttons here */
  background-color: #ccc !important; /* Change the background color */
  color: #666 !important; /* Change the text color */
  cursor: no-drop !important; /* Change the cursor style */
}
/* end styling slider image */

/* start styling slider */
.slider-comp .email-subject .text-header,
.range-slider .text-header {
  margin-left: 5px;
}
.slider-comp .range-slider,
.slider-comp .count-slider,
.slider-comp .count-step-slider,
.slider-comp .unit-slider {
  margin-top: 40px;
}

.slider-comp .unit-slider {
  margin-bottom: 80px;
}

.slider-comp .range-slider .range-cont {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
}

.slider-comp .range-slider .range-cont .from-text,
.slider-comp .range-slider .range-cont .to-text {
  width: 130px !important;
}

.slider-comp .range-slider .error-range {
  color: #d32f2f;
  font-size: 13px;
  margin-left: 5px;
}

.slider-comp .count-step-slider .count-step-cont {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
}

.slider-comp .count-step-slider .count-step-cont .count-p {
  font-size: 14px;
}
.slider-comp .count-step-slider .error-count-step {
  color: #d32f2f;
  font-size: 13px;
  margin-top: 5px;
}

.slider-comp .count-step-slider .des-count-step {
  color: #ccc;
  font-size: 12px;
  margin-top: 5px;
}

.slider-comp .count-slider .count-cont {
  display: flex;
  justify-content: space-between;
  gap: 20px;
}

.slider-comp .count-slider .count-cont .enable-move {
  font-size: 14px;
}

.slider-comp .count-slider .count-cont .des-enable-move {
  color: #ccc;
  font-size: 12px;
}

.slider-comp .unit-slider .unit-cont {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 50px;
}

.slider-comp .unit-slider .unit-cont .unit-p {
  font-size: 14px;
}

.email-subject .slider-error-message {
  font-weight: 800;
  font-size: 13px !important;
}
/* end styling slider */

/* start styling file upload */
.slider-comp .files-uploads-cont {
  margin: 20px 0px;
}

.slider-comp .files-uploads-cont .error-files-uploads {
  color: #ccc;
  font-size: 12px;
}

.email-subject .file-upload-error-message {
  font-weight: 800;
  font-size: 13px !important;
}
/* end styling file upload */

/* start styling the calendar */
.date-selection button {
  width: 50%;
  background-color: var(--simple-back-ground-color);
  border: none;
  font-size: 1.1rem;
  height: 40px;
}

.date-selection button:hover {
  background-color: var(--back-ground-color);
}

.email-subject {
  display: flex;
  flex-direction: column;
  margin: 20px 0px 40px 0px;
}

.email-subject Stack {
  margin-top: 10px;
}

.email-subject .time-error-msg,
.email-subject .date-error-msg {
  font-weight: 800;
  font-size: 13px !important;
}

.email-subject .remaining-text {
  font-weight: 800;
  color: var(--main-color);
}

.drop-down {
  display: flex;
  flex-direction: column;
}

.exclude-specific-dates input {
  width: 100%;
  margin-top: 5px;
  outline: none;
}

.exclude-specific-dates input::-webkit-calendar-picker-indicator {
  color: var(--main-color);
  cursor: pointer;
}

.exclude-specific-dates .dates-container {
  display: flex;
  align-items: center;
  border: 1px solid var(--border-color);
  border-radius: 5px;
  height: 100px;
  max-height: 100px;
}

.exclude-specific-dates .dates-container .fa-calendar {
  color: #2a7cff;
  font-size: 1.5rem;
  min-height: 100%;
  display: flex;
  align-items: center;
  padding: 0 20px;
  justify-content: center;
  cursor: pointer;
}

.exclude-specific-dates .dates-container .exclude-date-box {
  overflow: auto;
  height: 100%;
  max-height: 300px;
  width: 100%;
  font-size: 1rem;
  border-left: 1px solid var(--border-color);
}

.exclude-specific-dates .dates-container .all-excluded-days {
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin: 8px 20px;
}

.exclude-specific-dates
  .dates-container
  .all-excluded-days
  .delete-excluded-day {
  cursor: pointer;
}

.exclude-specific-dates
  .dates-container
  .all-excluded-days
  .delete-excluded-day:hover {
  color: red;
}

CalendarMonthTwoToneIcon {
  color: #2a7cff !important;
}

.calendar-info {
  font-size: 11px !important;
  margin-top: 5px !important;
  margin-left: 10px !important;
}

/* start calender shape */

.rdp {
  --rdp-cell-size: 40px;
  --rdp-caption-font-size: 18px;
  --rdp-accent-color: #00f;
  --rdp-background-color: #e7edff;
  --rdp-accent-color-dark: #3003e1;
  --rdp-background-color-dark: #180270;
  --rdp-outline: 2px solid #00f;
  --rdp-outline-selected: 3px solid #00f;
  --rdp-selected-color: #fff;
  margin: 1em;
}
.rdp-months {
  display: block !important;
}
.rdp-month:last-child {
  margin-right: 0;
}
.rdp-month:first-child {
  margin-left: 0;
}
.rdp-month {
  margin: 0 1em;
}

.rdp-button:hover:not([disabled]):not(.rdp-day_selected) {
  background-color: #9369d6 !important;
}

.rdp-table {
  border-collapse: inherit !important;
  padding: 5px 0px !important;
  margin: 0;
  max-width: calc(47 * 7);
}

.rdp-caption {
  align-items: center;
  display: flex;
  justify-content: space-between;
  text-align: left;
  background-color: #6e48aa;
  border-bottom: 1px solid #7d7d7d4a;
  border-radius: 4px;
  color: #fff;
  padding: 5px 10px !important;
}
.rdp-caption_label {
  align-items: center;
  border: 2px solid #0000;
  color: currentColor;
  font-family: inherit;
  font-size: 18px;
  font-weight: 700;
  margin: 0;
  padding: 0 0.25em;
  white-space: nowrap;
  z-index: 1;
}
.rdp-caption_dropdowns,
.rdp-caption_label {
  display: inline-flex;
  position: relative;
}
.rdp-button:not([disabled]) {
  cursor: pointer;
}
.rdp-nav {
  white-space: nowrap;
}
.rdp-nav_button {
  align-items: center;
  border-radius: 100%;
  display: inline-flex;
  height: 40px;
  justify-content: center;
  padding: 0.25em !important;
  width: 40px;
}
.rdp-button {
  border: 2px solid #0000;
}

.rdp-cell {
  height: 100%;
  height: 40px;
  padding: 0;
  text-align: center;
  width: 40px;
}
.rdp-button_reset {
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  background: none;
  color: inherit;
  cursor: default;
  font: inherit;
  margin: 0;
  padding: 0;
  position: relative;
}
.rdp-day,
.rdp-weeknumber {
  align-items: center;
  border: 2px solid #0000;
  border-radius: 100%;
  box-sizing: border-box;
  display: flex;
  height: 40px;
  justify-content: center;
  margin: 0;
  max-width: 40px;
  overflow: hidden;
  width: 40px;
}
.rdp-head {
  border: 0;
}
.rdp-head_cell {
  font-size: 0.75em;
  font-weight: 700;
  height: 100%;
  height: 47px;
  padding: 0;
  text-align: center;
  text-transform: uppercase;
  vertical-align: middle;
}
.rdp-button[disabled]:not(.rdp-day_selected) {
  opacity: 0.25;
}
.rdp-day_today:not(.rdp-day_outside) {
  font-weight: 700;
}
.rdp-day_selected,
.rdp-day_selected:focus-visible,
.rdp-day_selected:hover {
  background-color: var(--rdp-accent-color);
  color: var(--rdp-selected-color);
  opacity: 1;
}
.rdp-head_row,
.rdp-row {
  height: 100%;
}
/* end calender shape */

.exclude-time .duration-cont {
  margin-top: 20px;
}

.exclude-time .duration-cont .duration-hint {
  color: #ccc;
  font-size: 12px;
  margin-top: 3px;
}

.exclude-time .default-time-container {
  margin-top: 20px;
}

.exclude-time .selected-specific-time {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  gap: 10px;
  margin: 0px 0px 20px 0px;
  border: 1px solid #ccc;
  padding: 10px;
  border-radius: 4px;
  background-color: #fcfcfc;
}

.exclude-time .selected-specific-time .from-box,
.exclude-time .selected-specific-time .to-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  flex: 1;
}

.exclude-time .selected-specific-time .from-box .from-text,
.exclude-time .selected-specific-time .to-box .to-text {
  width: 100%;
}

.exclude-time .set-specific-time-to-date {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  margin: 0px 0px 20px 0px;
  border: 1px solid #ccc;
  padding: 10px 10px 20px;
  border-radius: 4px;
  background-color: #fcfcfc;
}

.exclude-time .set-specific-time-to-date .time-cont {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 100%;
}

.exclude-time .set-specific-time-to-date .time-cont .spicfic-from-box,
.exclude-time .set-specific-time-to-date .time-cont .spicfic-to-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  flex: 1;
}

.exclude-time
  .set-specific-time-to-date
  .time-cont
  .spicfic-from-box
  .from-text,
.exclude-time .set-specific-time-to-date .time-cont .spicfic-to-box .to-text {
  width: 100%;
}

.exclude-time
  .set-specific-time-to-date
  .restore-spicific-time
  .restore-btn-time {
  font-weight: 800 !important;
  transition: all 0.3s !important;
  background-color: var(--second-color) !important;
  color: var(--main-color) !important;
  font-weight: 800 !important;
  min-width: 110px !important;
  min-height: 40px !important;
  transition: all 0.3s !important;
  box-shadow: 1px 1px 3px #ccc !important;
}

.exclude-time
  .set-specific-time-to-date
  .restore-spicific-time
  .restore-btn-time:hover {
  background-color: #d7c2c2 !important;
  box-shadow: 1px 1px 13px #ccc !important;
}

.exclude-time
  .set-specific-time-to-date
  .restore-spicific-time
  .restore-btn-time:active {
  background-color: #d7c2c2 !important;
  box-shadow: 1px 1px 13px #ccc !important;
}

.exclude-time .specific-time-container {
  width: 100%;
}

.css-6hp17o-MuiList-root-MuiMenu-list {
  padding: 0px !important;
}

.menu-select {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  font-size: 18px !important;
}
.css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root {
  cursor: pointer !important;
}

.menu-select:not(:last-child) {
  border-bottom: 1px solid var(--border-color) !important;
}

.exclude-time .button-container {
  margin: 20px 0 80px 0;
}

.exclude-time .button-container .btn-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.exclude-time .button-container table {
  width: 100%;
  text-align: center;
  box-shadow: 0px 0px 12px 1px #ccc;
  margin-top: 20px;
  padding: 0;
}

.exclude-time .button-container table .table-head {
  color: white;
}

.exclude-time .button-container table th:nth-child(odd) {
  background-color: #324960;
}

.exclude-time .button-container table th:nth-child(even) {
  background-color: #46a0cd;
}

.exclude-time .button-container table .table-body:nth-child(odd) {
  background-color: #ffffff;
}

.exclude-time .button-container table .table-body:nth-child(even) {
  background-color: #ebe9e9;
}

.exclude-time .button-container table td {
  padding: 5px;
}

/* end styling the calendar */

/* start styling send an email */
.email-input-normal {
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
}

.email-input-normal .all-normal-emails {
  border: 1px solid var(--border-color);
  border-radius: 5px;
  overflow: auto;
  min-height: 30px;
  max-height: 100px;
  padding: 10px;
}

.email-input-normal .all-normal-emails .single-noraml-email {
  background-color: #f6fafd;
  padding: 10px;
  border-radius: 5px;
}

.email-input-normal .all-normal-emails .single-noraml-email:not(:last-child) {
  margin-bottom: 10px;
}

.email-input {
  display: flex;
  flex-direction: column;
}

.email-input input {
  appearance: none;
  width: 100%;
  height: 35px;
  padding: 12px 12px;
  border: 1px solid gray;
  border-radius: 5px;
}

.email-input input:focus {
  outline: none;
  border-color: var(--main-color);
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1);
}

.email-input label {
  margin: 5px 0;
}

.sub-email {
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
}

.email-markup {
  padding-bottom: 30px;
}
/* end styling send an email */

/* start styling the buttons */
.add-btn-list .buttons-info {
  display: flex;
  margin-bottom: 30px;
  flex-direction: column;
}

.add-btn-list .buttons-info .all-button-data {
  display: flex;
  align-items: center;
  justify-content: center;
}

.add-btn-list .buttons-info .name-area {
  flex: 2;
}

.add-btn-list .button-name {
  width: 100%;
}

.add-btn-list .buttons-info .delet-btn {
  margin-left: 10px;
}

.add-btn-list .buttons-info .delet-btn .delete-outlined-icon {
  cursor: pointer;
}
/* end styling the buttons */

/* start styling the delay */
.css-d9c359-MuiButtonBase-root-MuiToggleButton-root.Mui-selected {
  color: #ffffff !important;
  background-color: #2a7cff !important;
}
/* end styling the delay */

/* start styling carousel */

/* carousal card details header */

.card-details-header-cont {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 25px;
}

.card-details-header-cont .Back-to-list-cont {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  height: 65px;
}

.card-details-header-cont .Back-to-list-cont .left-arrow-icon-cont {
  display: flex;
  justify-content: center;
  align-items: center;
}

.card-details-header-cont .Back-to-list-cont .back-top-form {
  margin: 0px !important;
  text-transform: capitalize !important;
}

.card-details-header-cont .close-icon-cont {
  cursor: pointer;
}

/* carousal card details */
.card-Details-cont {
  border-bottom: 3px solid #7d7d7d;
}

.card-Details-cont .image-card-details-box {
  width: 100%;
  height: 168px;
  border-radius: 4px;
  background-color: #fafafa;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

.card-Details-cont .image-card-details-box .card-image {
  max-width: 100%;
  max-height: "100%";
}

.card-Details-cont .carousel-upload-image-cont {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 25px;
}

.card-Details-cont .carousel-upload-image-cont .upload-img {
  margin: 20px 0px 0px !important;
  background-color: var(--second-color) !important;
  color: var(--main-color) !important;
  font-weight: 800 !important;
  min-width: 110px !important;
  min-height: 40px !important;
  transition: all 0.3s !important;
  box-shadow: 1px 1px 3px #ccc !important;
  padding: 10px 40px !important;
  text-transform: none !important;
}

.card-Details-cont .carousel-upload-image-cont .upload-img:hover {
  background-color: #d7c2c2 !important;
  box-shadow: 1px 1px 13px #ccc !important;
}

.card-Details-cont .carousel-upload-image-cont .upload-img:active {
  background-color: #d7c2c2 !important;
  box-shadow: 1px 1px 13px #ccc !important;
}

.card-Details-cont .carousel-upload-image-cont .upload-img[disabled] {
  background-color: #ccc !important;
  color: #666 !important;
  cursor: no-drop !important;
}
/* Carousal buttons */
.add-branch-btn,
.add-URL-btn {
  background-color: var(--second-color) !important;
  color: var(--main-color) !important;
  font-weight: 800 !important;
  min-width: 110px !important;
  min-height: 40px !important;
  transition: all 0.3s !important;
  box-shadow: 1px 1px 3px #ccc !important;
  text-transform: none !important;
  padding: 10px 15px !important;
}

.add-branch-btn:hover,
.add-URL-btn:hover {
  background-color: #d7c2c2 !important;
  box-shadow: 1px 1px 13px #ccc !important;
}

.add-branch-btn:active,
.add-URL-btn:active {
  background-color: #d7c2c2 !important;
  box-shadow: 1px 1px 13px #ccc !important;
}

.add-branch-btn[disabled],
.add-URL-btn[disabled] {
  /* Add your custom styles for disabled buttons here */
  background-color: #ccc !important; /* Change the background color */
  color: #666 !important; /* Change the text color */
  cursor: no-drop !important; /* Change the cursor style */
}

/* end styling carousel */
