.closeModalBtn {
  position: absolute !important;
  top: 2px !important;
  right: 2px !important;
  z-index: 9003 !important;
  background-color: red !important;
  border: none !important;
  color: white !important;
  cursor: pointer !important;
  min-width: 30px !important;
}
.closeModalBtn .closeIcon {
  font-weight: bolder;
  font-size: 25px;
}
.modalImage {
  width: 100% !important;
  height: 100% !important;
}
