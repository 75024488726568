.headerCont {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: 20px;
}

.headerCont .botBuilderCont{
  flex: 1;
}

.headerCont .botBuilderCont .titleFirst {
  font-size: 22px;
  font-weight: normal;
}

.headerCont .botBuilderCont .discriptionFirst {
  font-size: 15px;
  color: gray;
  width: 60%;
}

.theWholeMenu {
  padding: 15px 20px !important ;
}

.addIconCont {
  display: flex;
  justify-content: center;
  align-items: center;
}

.addIcon {
  color: #7a5959 !important;
}
.menuCont {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.noMargin {
  padding: 0 !important;
  margin: 0 !important;
}

.secondParagraph {
  color: #858585 !important;
  font-size: 11px !important;
  line-height: 1.4 !important;
}

.designBtn {
  background-color: var(--second-color) !important;
  color: var(--main-color) !important;
  font-weight: 800 !important;
  width: fit-content;
  transition: all 0.3s !important;
  padding: 5px 10px !important;
  box-shadow: 1px 1px 3px #ccc !important;
  text-transform: none !important;
  padding: 10px 40px !important;
}

.designBtn:hover {
  background-color: #d7c2c2 !important;
  box-shadow: 1px 1px 12px #ccc !important;
}

.designBtn:active {
  background-color: #d7c2c2 !important;
  box-shadow: 1px 1px 12px #ccc !important;
}

.designBtn[disabled] {
  /* Add your custom styles for disabled buttons here */
  background-color: #ccc !important; /* Change the background color */
  color: #666 !important; /* Change the text color */
  cursor: no-drop !important; /* Change the cursor style */
}
