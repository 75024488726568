body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
a{
  text-decoration: none;
  color: #333333;
}
a:hover{
  color: #333333;

}

.page {
  min-height: 100vh;
}

.p-20 {
  padding: 20px;
}
.m-20 {
  margin: 20;
}
.p-relative {
  position: relative;
}
.d-flex {
  display: flex;
}
.w-full {
  width: 100%;
}
.content {
  overflow: hidden;
}

.borderLineBottom {
  border-bottom: 1px solid #f7f6f6;
  padding-bottom: 5px;
}

.ql-editor {
  min-height: 50px !important;
  max-height: 150px;
  overflow: hidden;
  overflow-y: scroll;
}

.react-flow__attribution {
  display: none !important;
}

.ql-tooltip.ql-editing {
  left: 11.6016px !important;
  top: 45px !important;
}
