.reponsiveCont {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  color: white;
  padding: 10px;
  text-align: center;
  z-index: 1000000;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 15px;
}

.textCont {
  width: 320px;
}
.textCont h2 {
  color: black;
}
.textCont p {
  color: #858585;
  font-size: 16px;
}
