.sliderHeader .sliderNav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 65px;
}

.sliderHeader .sliderNav .instructionsPageCont {
  display: flex;
  justify-content: flex-end;
  margin-right: 12px;
  width: calc(100% - 109px);
}

.sliderHeader .sliderNav .instructionsPageCont .instructionPage {
  color: #4c7cff;
  cursor: pointer;
}

.sliderHeader .sliderNav .instructionsPageCont .instructionPage:hover {
  text-decoration: underline;
}

.sliderHeader .sliderNav .deleteCloseCont {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 68px;
  padding-right: 6px;
}

.sliderHeader .sliderNav .deleteCloseCont .deleteCont,
.sliderHeader .sliderNav .deleteCloseCont .closeCont {
  cursor: pointer;
}

.sliderHeader .sliderNav .deleteCloseCont .deleteCont .deleteIcon {
  font-size: 25px;
}

.sliderHeader .sliderNav .deleteCloseCont .deleteCont .deleteIcon:hover {
  color: red;
}

.sliderHeader .sliderNav .deleteCloseCont .closeCont .closeIcon {
  font-size: 22px;
}

.sliderCompBtnn {
  margin: 30px 0px;
}

.sliderCompBtnn .messageBox .message {
  width: 100%;
}

.sliderCompBtnn
  .messageBox
  .message
  .css-1x51dt5-MuiInputBase-input-MuiInput-input {
  font-size: 18px !important;
  font-weight: 600 !important;
}

.sliderCompBtnn
  .messageBox
  .message
  .css-v4u5dn-MuiInputBase-root-MuiInput-root:before {
  border-bottom: 2px solid #afb2b3 !important ;
}

.sliderCompBtnn .messageBox .messageDesc {
  font-size: 14px !important;
  color: #858585 !important;
  margin: 10px 0 !important;
  letter-spacing: normal !important;
  line-height: 1.5 !important;
}
