.designBtn {
  margin: 20px 0 35px 10px !important;
  background-color: var(--second-color) !important;
  color: var(--main-color) !important;
  font-weight: 800 !important;
  min-width: 110px !important;
  min-height: 40px !important;
  transition: all 0.3s !important;
  box-shadow: 1px 1px 3px #ccc !important;
}

.designBtn:hover {
  background-color: #d7c2c2 !important;
  box-shadow: 1px 1px 13px #ccc !important;
}

.designBtn:active {
  background-color: #d7c2c2 !important;
  box-shadow: 1px 1px 13px #ccc !important;
}

.designBtn[disabled] {
  /* Add your custom styles for disabled buttons here */
  background-color: #ccc !important; /* Change the background color */
  color: #666 !important; /* Change the text color */
  cursor: no-drop !important; /* Change the cursor style */
}
