/* Define custom scrollbar styles */
.list-container {
  overflow-y: scroll;
  scrollbar-width: thin;
  overflow-x: hidden;
  scrollbar-color: #666666 #f0f0f0;
}

.list-container::-webkit-scrollbar {
  width: 8px;
}

.list-container::-webkit-scrollbar-thumb {
  background-color: #858585;
  border-radius: 10px;
}

.list-container::-webkit-scrollbar-track {
  background-color: #f0f0f0;
}

/* .css-p0pzb2 {
    padding: 8px 2px !important;
  } */

.css-bshv44-MuiButtonBase-root-MuiListItem-root {
  padding: 5px 0px !important;
}
